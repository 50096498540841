import './developAndMonetizeLand.scss';
import { useInViewport } from 'react-in-viewport';
import { useEffect, useRef } from 'react';
import Typewriter from 'typewriter-effect';
import LazyLoad from '../common/lazyLoad';
import { LazyLoadComponent } from 'react-lazy-load-image-component';


function DevelopAndMonetizeLand() {


  const developMonetizeRef = useRef();

  const {
    inViewport,
  } = useInViewport(
    developMonetizeRef,
    { disconnectOnLeave: false },
  );


  useEffect(() => {
    let elem = document.getElementById('develop-monetize');
    if (inViewport) {
      setTimeout(() => {
        elem.classList.add('inView')
      }, 0)
    } else {
      setTimeout(() => {
        elem.classList.remove('inView')
      }, 0)
    }
  }, [inViewport]);


  return (
    <section className="develop-and-monetize-section dark-blue-bg" id="develop-monetize">
      {window.matchMedia("(min-width: 770px)").matches && <>

        <div className='develop-land-desktop container'>
          <div className='inner-container'>
            <div className='desktop-caption' ref={developMonetizeRef}>
              <div className='caption-inner'>
                <LazyLoad className='number_img' src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen8/03.png" />
                <h6 className='section-heading'>Develop <br /> Your Land</h6>
                <p>
                  {inViewport && <Typewriter
                    options={{
                      strings: 'Build your customised structures on top of the real world and expand your virtual economy.',
                      autoStart: true,
                      loop: false,
                      delay: 50
                    }}
                  />}
                </p>
                <div className='bottom-arrow-div'>
                  <LazyLoad alt="arrow" src="https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen6/Down_Arrows.png" />
                </div>
              </div>
            </div>
            <div className='frame-container'>
              <div className="video-upper-container">
                <div className='d-flex justify-content-end w-100 h-100'>
                  <div className='video-container'>
                    <LazyLoad src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen8/Frame.png" className='frame' />
                    <LazyLoadComponent>
                      <video loop autoPlay muted preload="none">
                        <source src={'https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen8/develop_and_monetize_video.mp4'} type="video/mp4" />
                      </video>
                    </LazyLoadComponent>
                  </div>
                </div>

              </div>
            </div>
          </div>
          {/* <LazyLoad alt="number" src="https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen8/03_Smaller.png" className='number' /> */}
        </div>
        {/* <LazyLoad className='position-absolute right_top_border' alt="img" src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen8/right_top_border.png'}/>
            <LazyLoad className='position-absolute right_down_border' alt="img" src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen8/right_down_border.png'}/>
                <div className='row m-0 h-100 position-relative'>
                <div className='left-section col-md-4'>
                    <div className='left-section-caption'>
                        <h6 className='section-heading'>
                            Develop And monetize land
                        </h6>
                        <p className='m-0'>
                            Virtual tiles of land can be utilised to build a virtual economy. Produce and trade goods with other players to earn income.
                        </p>
                    </div>
                </div>
                <div className='right-section'>
                <video loop autoPlay muted>
                        <source src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen8/develop_and_monetize_video.mp4'} type="video/mp4"/> 
                    </video>
                
                </div>
            </div> */}
      </>}

      {window.matchMedia("(max-width: 770px)").matches &&
        <div className='develop-land-mobile'>
          <div className='inner-container'>
            <div className='mobile-caption' ref={developMonetizeRef}>
              <div className='caption-inner'>
                <LazyLoad className='number_img jack-image' src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen8/03.png" />
                <h6 className='section-heading'>Develop <br /> Your Land</h6>
                <p className='mobile-caption-message'>
                  Virtual tiles of land can be utilised to build a virtual economy. Produce and trade goods with other players to earn income.
                </p>
              </div>
            </div>
            <div className='frame-container'>
              <div className="video-upper-container">
                <div className='d-flex justify-content-end w-100 h-100'>
                  <div className='video-container'>
                    <LazyLoad src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen8/Frame.png" className='frame' />
                    <LazyLoadComponent>
                      <video loop autoPlay muted preload="none">
                        <source src={'https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen8/develop_and_monetize_video.mp4'} type="video/mp4" />
                      </video>
                    </LazyLoadComponent>
                  </div>
                </div>

              </div>
            </div>
            <div className='bottom-arrow-div'>
                  <LazyLoad alt="arrow" src="https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen6/Down_Arrows.png" />
            </div>
          </div>
        </div>
      }

    </section>
  );
}

export default DevelopAndMonetizeLand;
