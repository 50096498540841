import './jack.scss';
import { useEffect, useRef, useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
// import { useInViewport } from 'react-in-viewport';

function Jack() {
  const [sequence, setSequence] = useState([
    'boudica',
    'doctor',
    'pirate',
    'ripper',
    'capitalist',
  ]);

  const [leftImage, setLeftImage] = useState(sequence[4] + '_left');
  const [middleImage, setMiddleImage] = useState(sequence[3] + '_gif');
  const [rightImage, setRightImage] = useState(sequence[2] + '_right');
  const jackRef = useRef();
  // const { inViewport } = useInViewport(jackRef, { disconnectOnLeave: false });
  const Arrow_persp_right = 'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/rightArrow.svg';
  const Arrow_persp_left = 'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/leftArrow.svg';


  const onClickRightArrow = () => {
    document.getElementById('gamePlay').classList.add('clicked');
    setTimeout(() => {
      document.getElementById('gamePlay').classList.remove('clicked');
    }, 500)
    setSequence((oldSeq) => {
      let seq = oldSeq.slice();
      let lastEle = seq.splice(4, 1);
      seq.unshift(lastEle[0]);
      return seq;
    });

  }

  useEffect(() => {
    setMiddleImage(sequence[3] + '_gif');
    setRightImage(sequence[2] + '_right');
    setLeftImage(sequence[4] + '_left');
  }, [sequence])


  const onClickLeftArrow = () => {
    document.getElementById('gamePlay').classList.add('clicked');
    setTimeout(() => {
      document.getElementById('gamePlay').classList.remove('clicked');
    }, 500)
    let seq = [...sequence];
    let firstEle = seq.splice(0, 1);
    seq.push(firstEle[0]);
    setSequence(seq);
    setMiddleImage(seq[3] + '_gif');
    setRightImage(seq[2] + '_right');
    setLeftImage(seq[4] + '_left');
  }

  const renderLeftImage = () => {
    return (
      <>
        <img style={leftImage === "ripper_left" ? { display: 'block' } : { display: 'none' }} rel="preload" className={`left_jack jack_img ripper_left`} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/Black and white/Ripper_left.png'} alt="jack" />
        <img style={leftImage === "capitalist_left" ? { display: 'block' } : { display: 'none' }} rel="preload" className={`left_jack jack_img capitalist_left`} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/Black and white/Capitalist_left.png'} alt="jack" />
        <img style={leftImage === "boudica_left" ? { display: 'block' } : { display: 'none' }} rel="preload" className={`left_jack jack_img boudica_left`} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/Black and white/Boudica_left.png'} alt="jack" />
        <img style={leftImage === "doctor_left" ? { display: 'block' } : { display: 'none' }} rel="preload" className={`left_jack jack_img doctor_left`} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/Black and white/Doctor_left.png'} alt="jack" />
        <img style={leftImage === "pirate_left" ? { display: 'block' } : { display: 'none' }} rel="preload" className={`left_jack jack_img pirate_left`} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/Black and white/Pirate_left.png'} alt="jack" />
      </>
    )
  }

  const renderMiddleImage = () => {

    return (
      <>
        <img style={middleImage === "ripper_gif" ? { display: 'block' } : { display: 'none' }} rel="preload" className={`middle_jack jack_img ripper_gif`} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/GIF_Ripper.gif'} alt="jack" />
        <img style={middleImage === "boudica_gif" ? { display: 'block' } : { display: 'none' }} rel="preload" className={`middle_jack jack_img boudica_gif`} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/GIF_Boudica.gif'} alt="jack" />
        <img style={middleImage === "capitalist_gif" ? { display: 'block' } : { display: 'none' }} rel="preload" className={`middle_jack jack_img capitalist_gif`} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/GIF_Capitalist.gif'} alt="jack" />
        <img style={middleImage === "doctor_gif" ? { display: 'block' } : { display: 'none' }} rel="preload" className={`middle_jack jack_img doctor_gif`} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/GIF_Doctor.gif'} alt="jack" />
        <img style={middleImage === "pirate_gif" ? { display: 'block' } : { display: 'none' }} rel="preload" className={`middle_jack jack_img pirate_gif`} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/GIF_Pirate.gif'} alt="jack" />
      </>
    )
  }

  const renderRightImage = () => {

    return (
      <>
        <>
          <img style={rightImage === "ripper_right" ? { display: 'block' } : { display: 'none' }} rel="preload" className={`right_jack jack_img ripper_right`} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/Black and white/Ripper_right.png'} alt="jack" />
          <img style={rightImage === "boudica_right" ? { display: 'block' } : { display: 'none' }} rel="preload" className={`right_jack jack_img boudica_right`} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/Black and white/Boudica_right.png'} alt="jack" />
          <img style={rightImage === "capitalist_right" ? { display: 'block' } : { display: 'none' }} rel="preload" className={`right_jack jack_img capitalist_right`} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/Black and white/Capitalist_right.png'} alt="jack" />
          <img style={rightImage === "doctor_right" ? { display: 'block' } : { display: 'none' }} rel="preload"
            className={`right_jack jack_img doctor_right`} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/Black and white/Doctor_right.png'} alt="jack" />
          <img style={rightImage === "pirate_right" ? { display: 'block' } : { display: 'none' }} rel="preload"
            className={`right_jack jack_img pirate_right`} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/Black and white/Pirate_right.png'} alt="jack" />
        </>
      </>
    )
  }

  // useEffect(() => {
  //   let intervalId = "";
  //   if (inViewport) {
  //     intervalId = setInterval(() => {
  //       onClickRightArrow();
  //     }, 8300);
  //   }

  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   }
  // }, [inViewport]);


  return (
    <section className="dark-blue-bg jack-section" id="gamePlay">
      <LazyLoadComponent>
        <div className='container jack-caption text-center'>
          <h6 className='section-heading'>
            The Jacks | Who will you find?
          </h6>
          <p>Jacks are encrypted characters from the past who have leaked into our world from a parallel dimension.</p>
          {window.matchMedia("(min-width: 821px)").matches &&
            <div onClick={onClickLeftArrow} className="cursor-pointer">
              {renderLeftImage()}
            </div>
          }
          <>
            {renderMiddleImage()}
          </>
          {window.matchMedia("(min-width: 821px)").matches &&
            <div onClick={onClickRightArrow} className="cursor-pointer">
              {renderRightImage()}
            </div>
          }
        </div>
      </LazyLoadComponent>

      <div className='arrow-div' ref={jackRef}>
        <img className='left' onClick={onClickLeftArrow} src={Arrow_persp_left} alt="img" />
        <img className='right' onClick={onClickRightArrow} src={Arrow_persp_right} alt="img" />
      </div>
      <div className='dots-container'>
        <div className={`dots ${middleImage === 'ripper_gif' ? 'selected' : ''}`}></div>
        <div className={`dots ${middleImage === 'pirate_gif' ? 'selected' : ''}`}></div>
        <div className={`dots ${middleImage === 'doctor_gif' ? 'selected' : ''}`}></div>
        <div className={`dots ${middleImage === 'boudica_gif' ? 'selected' : ''}`}></div>
        <div className={`dots ${middleImage === 'capitalist_gif' ? 'selected' : ''}`}></div>
      </div>
    </section>
  );
}

export default Jack;
