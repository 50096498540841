
import LazyLoad from '../common/lazyLoad';
import './growYourKingdom.scss';
import { LazyLoadComponent } from 'react-lazy-load-image-component';


function GrowYourKingdom() {


  return (
    <LazyLoadComponent>
        <section className="grow-your-kingdom-section dark-blue-bg" id="evolveGame">
              <div className='grow-your-kingdom-caption text-center' >
                  <h6 className='section-heading'>
                      Grow your kingdom
                  </h6>
                  <p>
                      Compete or join forces within the community to control and develop virtual tiles overlaid upon real locations. Visualise it all in Augmented Reality.
                  </p>
              </div>
              <div className='gif-wrapper'>
                <LazyLoad src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen3/grow_your_kingdom_gif.gif'}/>
              </div>
        </section>  
    </LazyLoadComponent>
  );
}

export default GrowYourKingdom;
