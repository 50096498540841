import './contactUs.scss';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Footer from '../footer/footer';
import { useEffect, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const ToastMessage = ({message, type}) => {


    const [show, setShow] = useState(false);

    useEffect(() => {
        if(message){
            setShow(true);
        }else{
            setShow(false); 
        }
    }, [message]);

    return (<Toast onClose={() => setShow(false)}  show={show} bg={type} delay={3000} autohide>
      <Toast.Body>{message}</Toast.Body>
    </Toast>);
}

function ContactUs() {

    const [fieldObj, setFieldObj] = useState({fullName : '', email : '', message: '', discord_link : ''});
    const [message, setMessage] = useState('');
    const [toastType, setToastType] = useState('');
    const email_regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

    
   

    const onChange = (event, name) => {
        setFieldObj({...fieldObj, [name] : event.target.value})
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if(!fieldObj.fullname){
            setMessage('Please enter Full Name');
            setToastType('danger');
            return;
        }else if(!fieldObj.email){
            setMessage('Please enter Email Address');
            setToastType('danger');
            return;
        }else if(!email_regex.test(fieldObj.email)){
            console.log('here...')
            setMessage('Please enter valid Email Address');
            setToastType('danger');
            return;
        }
        // else if(!fieldObj.message){
        //     setMessage('Please enter message');
        //     setToastType('danger');
        //     return;
        // }
        setMessage('');
        setToastType('');
        sendEmail();
    }

    const sendEmail = () => {
        window.Email.send({
            SecureToken : 'e32bd16c-bc38-4b80-b928-2f3accb36cd2',
            To : 'admin@planetxr.io',
            From : 'admin@planetxr.io',
            Subject : `Jack Message`,
            Body : 
            `Name : ${fieldObj.fullname}<br/> 
            Discord Link: ${fieldObj.discord_link ? fieldObj.discord_link : '--'}<br/>  
            Email: ${fieldObj.email}<br/> 
            Message: ${fieldObj.message}<br/> `
          }).then(
          (message ) => {
            if(message === 'OK'){
                setMessage('Email Send Successfully');
                setToastType('success');
                setFieldObj({fullName : '', email : '', message: '', discord_link : ''})
            }
          }).catch((err) => {
            setMessage('There is some issue, Please try after sometime!');
                setToastType('warning');
          });
    }
    

  return (
<section className="dark-blue-bg contact-us-section" id="contactUs">
        <div className='row m-0' style={{height : '85%'}}>
            <div className='col-sm-6 contact-us-caption-col'>
                <div className='contact-us-caption'>
                    <h6 className='section-heading'>Contact Us</h6>
                    <p>Wanna hear more about our project? Fill in the form and let’s get in touch!</p>
                </div>
            </div>
            <div className='col-sm-6 contact-us-form-col'>
                <div className='form-box-upper-wrapper'>
                    <div className='form-box'>
                        <Form>
                            <div className='row m-0 h-100'>
                                <div className='col-sm-6'>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control placeholder='Full Name' value={fieldObj.fullname} onChange={(e) => onChange(e, 'fullname')} name="fullname" className='input-field-contact-us' type="text" />
                                    </Form.Group>
                                </div>
                                <div className='col-sm-6'>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Enter Discord Username</Form.Label>
                                        <Form.Control placeholder='Username#0000' value={fieldObj.discord_link} onChange={(e) => onChange(e, 'discord_link')} name="discord_link" className='input-field-contact-us' type="text" />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className='row m-0 h-100'>
                                <div className='col-sm-12'>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control placeholder='Enter your Email' value={fieldObj.email} name="email" onChange={(e) => onChange(e, 'email')} className='input-field-contact-us' type="text" />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row m-0 h-100'>
                                <div className='col-sm-12'>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control placeholder='Write a message (max 200 characters)' maxLength={200} value={fieldObj.message} onChange={(e) => onChange(e, 'message')} name="message" className="input-field-contact-us text-area" as="textarea" rows="10"/>
                                    </Form.Group>
                                </div>
                            </div>  
                            <div className='row m-0 h-100'>
                                <div className='col-sm-12'>
                                    <Button variant="primary" className='send-btn' type="submit" onClick={(e) => onSubmit(e)}>
                                        SEND
                                    </Button>
                                </div>
                            </div>                       
                        </Form>
                    </div>
                </div>
            </div>
        </div>
        {message && <ToastMessage message={message} type={toastType}/>}
        <Footer/>
    </section>
  );
}

export default ContactUs;
