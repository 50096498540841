import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './header.scss';
import logoImage from '../../assets/logo.svg';
import { useState, useEffect } from 'react';

function Header() {

  const [expanded, setExpanded] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [prevDirection, setPrevDirection] = useState(0);
  const [handle, setHandle] = useState('');
  const [displayHeader, setDisplaHeader] = useState(true);

  const handleScroll = () => {
    document.getElementById('scroll-container-id').classList.remove('idle');
    if (handle) {
      clearTimeout(handle);
    }
    setHandle(setTimeout(() => {
      document.getElementById('scroll-container-id').classList.add('idle');
    }, 1000));


    let currentScrollPos = window.pageYOffset;
    if(window.matchMedia("(min-width: 450px)").matches){
      currentScrollPos = document.getElementById('scroll-container-id').scrollTop;
    }
    if(currentScrollPos === 0){
      setDisplaHeader(true);
    }else{
      setDisplaHeader(false);
    }
    // let direction = 0;

    // if (currentScrollPos > prevScrollPos) { 
    //   //scrolled up
    //   direction = 2;
    // }
    // else if (currentScrollPos < prevScrollPos) { 
    //   //scrolled down
    //   direction = 1;
    // }

    // if (direction !== prevDirection) {
    //   toggleHeader(direction, currentScrollPos);
    // }

    
    // setPrevScrollPos(currentScrollPos);
    
  }

  // const  toggleHeader = (direction, curScroll) => {
  //   let header = document.getElementById('jack-header-id');
  //    if (direction === 2 && curScroll > 100 && header) { 
  //     header.classList.remove('visible');
  //     setPrevDirection(direction)
  //   }
  //   else if (direction === 1 && header) {
  //     header.classList.add('visible');
  //     setPrevDirection(direction)
  //   }
  // };

  useEffect(() => {
    if(window.matchMedia("(min-width: 450px)").matches){
      document.getElementById('scroll-container-id').addEventListener('scroll', handleScroll);
    }else{
      window.addEventListener('scroll', handleScroll);
    }

    return () =>{
       document.getElementById('scroll-container-id').removeEventListener('scroll', handleScroll);
       window.addEventListener('scroll', handleScroll);
    };

  }, [prevScrollPos, handleScroll]);

  const scrollToTeams = () => {
    setExpanded(false);
    let section = document.getElementById('team');
    section.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
  const scrollToGamePlay = () => {
    setExpanded(false);
    let section = document.getElementById('capture-jacks');
    section.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
  const scrollToRoadmap = () => {
    setExpanded(false);
    let section = document.getElementById('roadmap');
    section.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
  const scrollToEvolveGame = () => {
    setExpanded(false);
    let section = document.getElementById('next-level-gaming-section-id');
    section.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
  const scrollToContact = () => {
    setExpanded(false);
    let section = document.getElementById('contactUs');
    section.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const scrollToHome = () => {
    setExpanded(false);
    let section = document.getElementById('home');
    section.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const showHeader =() => {
    setDisplaHeader(true);
  }
  const hideHeader = () => {
    let currentScrollPos = window.pageYOffset;
    if(window.matchMedia("(min-width: 450px)").matches){
      currentScrollPos = document.getElementById('scroll-container-id').scrollTop;
    }
    if(currentScrollPos !== 0){
    setDisplaHeader(false);
    }
  }

  return (
    <>
    {!displayHeader && <div className='header-transparent' onMouseOver={showHeader}></div>}
    {displayHeader &&  <Navbar collapseOnSelect expand="lg" onMouseLeave={hideHeader} className={`jack-header visible`} id="jack-header-id" expanded={expanded}>
        <Container>
          <Navbar.Brand onClick={scrollToHome}><img className="header-logo" src={logoImage}/></Navbar.Brand>
          {window.matchMedia("(min-width: 992px)").matches && <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick={scrollToGamePlay}>Gameplay</Nav.Link>
              <Nav.Link onClick={scrollToEvolveGame}>Evolve Game</Nav.Link>
              <Nav.Link onClick={scrollToRoadmap}>Roadmap</Nav.Link>
              {/* <Nav.Link onClick={scrollToTeams}>Team</Nav.Link> */}
              <Nav.Link onClick={scrollToContact}>Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>}
          <Nav className='social-icons-nav'>
              <Nav.Link href= 'https://twitter.com/JackGameApp' ><img src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/twitter.svg'}/></Nav.Link> 
              <Nav.Link href= 'https://discord.gg/aCbsV8ZK5Z'><img src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/discord.svg'}/></Nav.Link> 
              <Nav.Link href='https://www.instagram.com/jackgameapp/' target="_blank"><img src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/instagram.svg'}/></Nav.Link>
              <Nav.Link href= 'https://www.youtube.com/channel/UCexc78maqYBrp5Lrthrmcjw'><img src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/youtube.svg'}/></Nav.Link> 
              {/* <Nav.Link ><img src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/telegram.svg'}/></Nav.Link> */}
          </Nav>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)}  />

          {window.matchMedia("(max-width: 992px)").matches && 
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick={scrollToGamePlay}>Gameplay</Nav.Link>
              <Nav.Link onClick={scrollToEvolveGame}>Evolve Game</Nav.Link>
              <Nav.Link onClick={scrollToRoadmap}>Roadmap</Nav.Link>
              {/* <Nav.Link onClick={scrollToTeams}>Team</Nav.Link> */}
              <Nav.Link onClick={scrollToContact}>Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>}
        </Container>
      </Navbar>}
    </>
     
  );
}

export default Header;