import './banner.scss';
import Button from 'react-bootstrap/Button';
import { useInViewport } from 'react-in-viewport';
import { useEffect, useRef, useState } from 'react';
import buttonWatchTrailer from '../../assets/Button_Watch_Trailer.png';
import metaverseNuovoMobile from '../../assets/Metaverse_Nuovo_Mobile.gif';
import ArrowsDown from '../../assets/Arrows.png';
import Modal from 'react-modal';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {zIndex: 9999999}
  };

function Banner() {

    const bannerRef = useRef();
    const [showVideoFlag, setShowVideoFlag] = useState(false)
    const {
        inViewport,
    } = useInViewport(
        bannerRef,
        { disconnectOnLeave: false },
    );

    useEffect(() => {
        let elem = document.getElementById('side-banner-id');
        
    }, [inViewport]);

    const scrollToContact = () => {
        let section = document.getElementById('contactUs');
        section.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    const modalIsOpen = () => {
        setShowVideoFlag(true);
    }
    
    const closeModal = () => {
        setShowVideoFlag(false);
    }
    

    return (
        <section className="dark-blue-bg banner-section" id="home">
            {window.matchMedia("(min-width: 600px)").matches &&
            
            <Modal
                isOpen={showVideoFlag}
                onRequestClose={closeModal}
                style={customStyles}>
                <iframe width="854" height="480" src="https://www.youtube.com/embed/FwKOgyKyC8k?autoplay=1" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
            </Modal>  
            
            }

            {window.matchMedia("(max-width: 600px)").matches &&
            
            <Modal
                isOpen={showVideoFlag}
                onRequestClose={closeModal}
                style={customStyles}>
                <iframe width="300" height="200" src="https://www.youtube.com/embed/FwKOgyKyC8k?autoplay=1" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
            </Modal>  
            
            }

            {window.matchMedia("(min-width: 600px)").matches &&
            
                <div className='banner-desktop-container'>
                    <div className='frame-container'>

                        {window.matchMedia("(min-width: 1282px)").matches && <img src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen1/Banner_Metaverse_animation2.gif" alt="frame" />}
                        {!window.matchMedia("(min-width: 1282px)").matches && <img src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen1/Banner_Metaverse_animation2.gif" alt="frame" />}

                        {window.matchMedia("(min-width: 1282px)").matches && <img src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen1/Homeshadow.png" alt="frame" />}
                        {!window.matchMedia("(min-width: 1282px)").matches && <img src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen1/Homeshadow.png" alt="frame" />}
                    </div>
                    <div className='video-upper-container-watch'>
                            <div className='video-container-watch'>
                                <div className='heading d-flex justify-content-center'>
                                        <img className='btn-watch-image' src={buttonWatchTrailer} onClick={() => {modalIsOpen()}}></img>
                                </div>
                            </div>
                    </div>
                    <div className='video-upper-container'>
                            <div className='video-container'>
                                {/* <video loop autoPlay muted>
                                    <source src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen1/banner_smaller.mp4'} type="video/mp4" />
                                </video> */}
                                <div className='row button-row w-100 mb-3'>
                                    <div className='col-md-6'><a href="https://doc.clickup.com/20650203/d/h/kp66v-128/bb9143dace133fe" target="_blank"><Button>Documentation</Button></a></div>
                                    <div className='col-md-6 mb-2'><a href="https://docs.google.com/forms/d/1cZlwouFY_Uh2N_f-piFu81etG0GtP9BsqMqAi_beieQ/edit" target="_blank"><img src={"https://d14utwz37q7qa4.cloudfront.net/assets/images/screen1/pioneer_btn.svg"} /><Button>Play our Alpha</Button></a></div>
                                </div>
                            </div>
                    </div>

                    {/* <div className='bottom-caption'>
                        <p>Gather your Jacks, Build your Kingdom, and Compete for Real-World Dominance in Augmented Reality.</p>
                    </div> */}


                    {/* <div className='text-container dark-blue-bg'></div> */}
                </div>}
            {window.matchMedia("(max-width: 600px)").matches && <>
                <div className='banner-video'>
                    
                    {window.matchMedia("(max-width: 600px)").matches && <img src={metaverseNuovoMobile} alt="frame" className='mobile-banner-img'/>}
                    {!window.matchMedia("(max-width: 600px)").matches && <img src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen1/Metaverse_Nuovo_Mobile.gif" alt="frame" className='mobile-banner-img'/>}
                    {/* <video loop autoPlay muted>
                        <source src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen1/banner_mobile.mp4'} type="video/mp4" />
                    </video> */}
                </div>
                <div className='banner-caption text-center' ref={bannerRef}>
                    <div className='video-upper-container-watch'>
                            <div className='video-container-watch'>
                                <div className='heading d-flex justify-content-center'>
                                        <img className='btn-watch-image' src={buttonWatchTrailer} onClick={() => {modalIsOpen()}}></img>
                                </div>
                            </div>
                    </div>
                    <div className='video-container'>
                        {/* <h1>make your realm</h1> */}
                        <div className='row button-row w-100 m-0 mb-3'>
                            <div className='col-md-6 mb-2'><a href="https://docs.google.com/forms/d/1cZlwouFY_Uh2N_f-piFu81etG0GtP9BsqMqAi_beieQ/edit" target="_blank"><img src={"https://d14utwz37q7qa4.cloudfront.net/assets/images/screen1/pioneer_btn.svg"} /><Button>Play our Alpha</Button></a></div>
                            <div className='col-md-6 mb-2'><a href="https://doc.clickup.com/20650203/d/h/kp66v-128/bb9143dace133fe" target="_blank"><Button>Documentation</Button></a></div>
                        </div>
                        <div className='vid-text'>
                            <img src={ArrowsDown}></img>
                        </div>
                        {/* <div className='vid-text'>
                            <p>Gather your jacks, complete for dominance, and make <br /> your kingdom florish in Augumented Reality.</p>
                        </div> */}
                    </div>
                </div>
            </>}

        </section>)
}

export default Banner;