import './tradeAssets.scss';
import Typewriter from 'typewriter-effect';
// import $ from 'jquery';
import { useEffect, useState, useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import LazyLoad from '../common/lazyLoad';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

function TradeAssets() {

  const text = 'Trade in-game items and resources to grow your wealth.'
  const tradeAssetsRef = useRef();

  const {
    inViewport,
  } = useInViewport(
    tradeAssetsRef,
    { disconnectOnLeave: false },
  );

  useEffect(() => {
    let elem = document.getElementById('trade-assets');

    if (inViewport) {
      setTimeout(() => {
        elem.classList.add('inView');
      }, 0)
    } else {
      setTimeout(() => {
        elem.classList.remove('inView');
      }, 0)
    }
  }, [inViewport]);

  return (
    <section className="dark-blue-bg trade-assets-section" id="trade-assets">
      {window.matchMedia("(min-width: 770px)").matches && <>
        <div className='trade-assets-desktop container'>
          <div className='inner-container'>

            <div className='frame-container'>
              <div className="video-upper-container">
                <div className='d-flex justify-content-end w-100 h-100'>
                  <div className='video-container'>
                    <LazyLoad src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen8/Frame.png" className='frame' />
                    <LazyLoad className='trade-assets-gif' src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen9/trade_assets_video.gif'} />
                  </div>
                </div>

              </div>
            </div>
            <div className='desktop-caption' ref={tradeAssetsRef}>
              <div className='caption-inner'>
                <LazyLoad className='number_img' src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen9/04.png" />
                <h6 className='section-heading'>Trade Game <br /> Assets</h6>
                <p>
                  {inViewport && <Typewriter
                    options={{
                      strings: text,
                      autoStart: true,
                      loop: false,
                      delay: 50
                    }}
                  />}
                </p>
                <div className='bottom-arrow-div'>
                  <LazyLoad alt="arrow" src="https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen6/Down_Arrows.png" />
                </div>
              </div>
            </div>
          </div>
          {/* <LazyLoad alt="number" src="https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen9/04_Smaller.png" className='number' /> */}
        </div>
      </>
      }

      {window.matchMedia("(max-width: 770px)").matches &&
        <div className='trade-assets-mobile'>
          <div className='inner-container'>
            <div className='mobile-caption' ref={tradeAssetsRef}>
              <div className='caption-inner'>
                <LazyLoad className='number_img jack-image' src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen9/04.png" />
                <h6 className='section-heading'>Trade Game <br /> Assets</h6>
                <p className='mobile-caption-message'>
                  {text}
                </p>
              </div>
            </div>
            <div className='frame-container'>
              <div className="video-upper-container">
                <div className='d-flex justify-content-end w-100 h-100'>
                  <div className='video-container'>
                    <LazyLoad src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen8/Frame.png" className='frame' />
                    <LazyLoad style={{width: "100%"}} src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen9/trade_assets_video.gif'} />
                  </div>
                </div>

              </div>
            </div>
            <div className='bottom-arrow-div'>
                  <LazyLoad alt="arrow" src="https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen6/Down_Arrows.png" />
            </div>
          </div>
        </div>
      }

    </section>
  );
}

export default TradeAssets;
