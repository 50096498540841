import './App.scss';
import Header from '../header/header';
import Banner from '../banner/banner';
import Jack from '../jack/jack';
import GrowYourKingdom from '../growYourKingdom/growYourKingdom';
import ConquerMetaverse from '../conquerMetaverse/conquerMetasvers';
import CaptureJacks from '../captureJacks/captureJacks';
import Teams from '../teams/teams';
import JackDuels from '../jackDuels/jackDuels';
import TradeAssets from '../tradeAssets/tradeAssets';
import TechAndGame from '../techAndGame/techAndGame';
import DevelopAndMonetizeLand from '../developAndMonetizeLand/developAndMonetizeLand';
import ContactUs from '../contactUs/contactUs';
import Roadmap from '../roadmap/roadmap';
import NextLevelGaming from '../nextLevelGaming/nextLevelGaming';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    let banner = document.getElementById('side-banner-id');

    setTimeout(() => {
      banner && banner.classList.add('side-banner-div-animation');
    }, 100);
    setTimeout(() => {
      banner && banner.classList.remove('side-banner-div-animation');
    }, 3100);
  }, []);

  return (
    <div className="App scroll-container idle" id="scroll-container-id">
      <div className='side-banner-div' id="side-banner-id">
        <a href="https://discord.gg/aCbsV8ZK5Z" target="_blank"><img src="https://d14utwz37q7qa4.cloudfront.net/assets/images/Side_Banner.png" alt="banner" /></a>
        {/* <div className='content'>
          <p>Join Discord</p>
          <button></button>
        </div> */}
      </div>
      <Header />
      <Banner />
      <Jack />
      <GrowYourKingdom />
      {/* <ConquerMetaverse /> */}
      <NextLevelGaming />
      <CaptureJacks />
      <JackDuels />
      <DevelopAndMonetizeLand />
      <TradeAssets />
      {/* <TechAndGame /> */}
      {/* <Roadmap /> */}
      {/* <Teams /> */}
      <ContactUs />
    </div>
  );
}

export default App;
