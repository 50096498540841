import './footer.scss';
import ListGroup from 'react-bootstrap/ListGroup';
import LazyLoad from '../common/lazyLoad';



function Footer() {

  const scrollToHome = () => {
    let section = document.getElementById('home');
    section.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }


  return (
    <div className="footer-section">
        <div className='container position-relative p-0 d-flex footer-wrapper'>
            <div className='logo-container' onClick={scrollToHome}>
                <LazyLoad src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/logo.svg'} alt="logo"/>
            </div>
            <div className='social-icons-container'>
                 <ListGroup horizontal>
                    <ListGroup.Item><a href='https://twitter.com/JackGameApp' target="_blank"><LazyLoad src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/twitter.svg'} alt="twitter"/></a></ListGroup.Item>
                    <ListGroup.Item><a href='https://discord.gg/aCbsV8ZK5Z' target="_blank"><LazyLoad src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/discord.svg'} alt="discord"/></a></ListGroup.Item>
                    <ListGroup.Item><a href='https://www.instagram.com/jackgameapp/' target="_blank"><LazyLoad src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/instagram.svg'} alt="instagram"/></a></ListGroup.Item>
                    <ListGroup.Item><a href='https://www.youtube.com/channel/UCexc78maqYBrp5Lrthrmcjw' target="_blank"><LazyLoad src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/youtube.svg'} alt="youtube"/></a></ListGroup.Item>
                </ListGroup>
            </div>
            <div className='logo-container'>
            <a href='https://planetxr.io/' target="_blank"><LazyLoad src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/Planetxrlogo.svg'} alt="logo"/></a>
            </div>
        </div>
    </div>
  );
}

export default Footer;
