import './captureJacks.scss';
import { useEffect, useRef } from 'react';
import Typewriter from 'typewriter-effect';
import { useInViewport } from 'react-in-viewport';
import LazyLoad from '../common/lazyLoad';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import logoImage from '../../assets/logo.svg';

function CaptureJacks() {
  const text = ' Muster your crew by capturing Jacks that spawn throughout the world.';

  const capturejacksRef = useRef();

  const {
    inViewport,
  } = useInViewport(
    capturejacksRef,
    { disconnectOnLeave: false },
  );


  useEffect(() => {
    let elem = document.getElementById('capture-jacks');
    if (inViewport && elem) {
      elem.classList.add('inView')
    } else if (elem) {
      elem.classList.remove('inView')
      setTimeout(() => {
      }, 0)
    }
  }, [inViewport]);



  return (
    <section className="dark-blue-bg capture-jacks-section" id="capture-jacks">
      {window.matchMedia("(min-width: 770px)").matches &&
        <div className='capture-jack-desktop container'>
          <div className='inner-container'>
            <div className='desktop-caption' ref={capturejacksRef}>
              <div className='caption-inner'>
                <LazyLoad className='number_img jack-image' src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen6/01.png" />
                <h6 className='section-heading'>Capture <br /> Jacks</h6>
                {/* <p>{text}</p> */}
                <p>
                  {inViewport && <Typewriter
                    options={{
                      strings: text,
                      autoStart: true,
                      loop: false,
                      delay: 50
                    }}
                  />}
                </p>
                <div className='bottom-arrow-div'>
                  <LazyLoad alt="arrow" src="https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen6/Down_Arrows.png" />
                </div>
              </div>
            </div>
            <div className='frame-container'>
              <div className="video-upper-container">
                <div className='d-flex justify-content-end w-100 h-100'>
                  <div className='video-container'>
                    <LazyLoad src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen6/Frame.png" className='frame' />
                    <LazyLoadComponent>
                      <video loop autoPlay muted preload="none">
                        <source src={'https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen6/capture_jacks_video.mp4'} type="video/mp4" />
                      </video>
                    </LazyLoadComponent>
                  </div>
                </div>

              </div>
            </div>
          </div>
          {/* <LazyLoad alt="number" src="https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen6/01_Small.png" className='number' /> */}
        </div>
      }
      {window.matchMedia("(max-width: 770px)").matches &&
        <div className='capture-jack-mobile'>
          <div className="d-flex justify-content-center">
            <img src={logoImage}></img>
          </div>
          <div className='inner-container'>
            <div className='mobile-caption' ref={capturejacksRef}>
              <div className='caption-inner'>
                <LazyLoad className='number_img jack-image' src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen6/01.png" />
                <h6 className='section-heading'>Capture Jacks</h6>
                <p className="mobile-caption-message">
                {text}
                </p>
              </div>
            </div>
            <div className='frame-container'>
              <div className="video-upper-container">
                <div className='d-flex justify-content-end w-100 h-100'>
                  <div className='video-container'>
                    <LazyLoad src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen6/Frame.png" className='frame' />
                    <LazyLoadComponent>
                      <video loop autoPlay muted preload="none">
                        <source src={'https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen6/capture_jacks_video.mp4'} type="video/mp4" />
                      </video>
                    </LazyLoadComponent>
                  </div>
                </div>

              </div>
            </div>
            <div className='bottom-arrow-div'>
                  <LazyLoad alt="arrow" src="https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen6/Down_Arrows.png" />
            </div>
          </div>
        </div>
      }

    </section>

  );
}

export default CaptureJacks;
