import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const LazyLoad = ({ alt, src , className, onClick}) => (
      <LazyLoadImage
        alt={alt ? alt : ''}
        src={src}
        onClick = {onClick ? onClick : () => {}}
        className = {className ? className : ''}
         />
  );
  
  export default LazyLoad;