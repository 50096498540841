import './nextLevelGaming.scss';
import { useEffect, useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import LazyLoad from '../common/lazyLoad';

function NextLevelGaming() {
    const nextLevelGamingRef = useRef();

    const {
        inViewport,
    } = useInViewport(
        nextLevelGamingRef,
        { disconnectOnLeave: false },
    );

    useEffect(() => {
        let elem = document.getElementById('next-level-gaming-section-id');

        if (inViewport) {
            elem.classList.add('inView');

        } else {
            elem.classList.remove('inView');
        }
    }, [inViewport])


    return (
        <section className="next-level-gaming-section dark-blue-bg" id="next-level-gaming-section-id">
            {/* <h6 className='section-heading'>Next Level Gaming</h6> */}
            <div className='section-wrapper'>
                <div className='section-wrapper-inner' >
                    <div className='section-scroll-container container'>
                        {window.matchMedia("(max-width: 767px)").matches && <div className='row m-0'>
                            <div className='middle-box-mobile text-center'>
                                <div className='middle-box-caption'>
                                    <h6>
                                        Evolve Game
                                    </h6>
                                    <p>
                                        <h2>
                                             Next Level Gaming
                                        </h2>  
                                    </p>
                                </div>
                            </div>
                        </div>}

                        <div className='row m-0'>
                            <div className='col-md-6 limitations-box box-col' ref={nextLevelGamingRef}>
                                {window.matchMedia("(min-width: 768px)").matches && <div className='line-div limitations-line-img'>
                                    <LazyLoad src={"https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/pointer.png"} alt="img" className='small-circle' />
                                    <LazyLoad src={"https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/pointer.png"} alt="img" className='big-circle' />
                                </div>}
                                <div className='box-text'>
                                    <h6>
                                        Continuous Evolution
                                    </h6>
                                    <p>
                                        Organic expansion of the gaming world driven by players' interaction.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-6 immersive-technology-box box-col  d-flex justify-content-end'>
                                {window.matchMedia("(min-width: 768px)").matches && <div className='line-div immersive-technology-line-img'>
                                    <LazyLoad src={"https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/pointer.png"} alt="img" className='small-circle' />
                                    <LazyLoad src={"https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/pointer.png"} alt="img" className='big-circle' />
                                </div>}
                                <div className='box-text'>
                                    <h6>
                                        Immersive Experience
                                    </h6>
                                    <p>
                                        Limitless human connections and real-world interaction through XR.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {window.matchMedia("(min-width: 768px)").matches && <div className='row m-0 middle-row'>
                            <div className='col-lg-5 cols-md-6 col-6 middle-box text-center'>
                                <div className='middle-box-caption'>
                                    <h6>
                                        Evolve Game
                                    </h6>
                                    <p>
                                        <h2>
                                             Next Level Gaming
                                        </h2>  
                                    </p>
                                </div>
                            </div>
                        </div>}

                        <div className='row m-0'>
                            <div className='col-md-6 never-ending-box box-col'>
                                {window.matchMedia("(min-width: 768px)").matches && <div className='line-div never-ending-line-img'>
                                    <LazyLoad src={"https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/pointer.png"} alt="img" className='small-circle' />
                                    <LazyLoad src={"https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/pointer.png"} alt="img" className='big-circle' />
                                </div>}
                                <div className='box-text'>
                                    <h6>
                                        Player Empowerment
                                    </h6>
                                    <p>
                                        Unprecedented opportunity to unleash players' ambition and creativity.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-6 player-ownership-box d-flex justify-content-end box-col'>
                                {window.matchMedia("(min-width: 768px)").matches && <div className='line-div player-ownership-line-img'>
                                    <LazyLoad src={"https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/pointer.png"} alt="img" className='small-circle' />
                                    <LazyLoad src={"https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/pointer.png"} alt="img" className='big-circle' />
                                </div>}
                                <div className='box-text'>
                                    <div className='box-text-inner'>
                                        <h6>
                                            Inclusive Sustainability
                                        </h6>
                                        <p>
                                            Inclusive and democratic ecosystem inspiring real-life social interaction.
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NextLevelGaming;
