import './jackDuels.scss';
import { useInViewport } from 'react-in-viewport';
import { useEffect, useRef } from 'react';
import Typewriter from 'typewriter-effect';
import LazyLoad from '../common/lazyLoad';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

function JackDuels() {
  const text = 'Battle your Jacks in duels, raids and tournaments to win tokens and land.'


  const jackDulesRef = useRef();

  const {
    inViewport,
  } = useInViewport(
    jackDulesRef,
    { disconnectOnLeave: false },
  );

  useEffect(() => {
    let elem = document.getElementById('jack-duels');
    if (inViewport) {
      setTimeout(() => {
        elem.classList.add('inView')
      }, 0)
    } else {
      setTimeout(() => {
        elem.classList.remove('inView')
      }, 0)
    }
  }, [inViewport]);




  return (
    <section className="dark-blue-bg jack-duels-section" id="jack-duels">
      {window.matchMedia("(min-width: 770px)").matches && <>
        <div className='jack-duels-desktop container'>
          <div className='inner-container'>

            <div className='frame-container'>
              <div className="video-upper-container">
                <div className='d-flex justify-content-end w-100 h-100'>
                  <div className='video-container'>
                    <LazyLoad src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen8/Frame.png" className='frame' />
                    <LazyLoadComponent>
                      <video loop autoPlay muted preload="none">
                        <source src={'https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen7/video_jack_duels.mp4'} type="video/mp4" />
                      </video>
                    </LazyLoadComponent>
                  </div>
                </div>

              </div>
            </div>
            <div className='desktop-caption' ref={jackDulesRef}>
              <div className='caption-inner'>
                <LazyLoad className='number_img' src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen7/02_num.png" />
                <h6 className='section-heading'>challenge other<br /> players</h6>
                <p>
                  {inViewport && <Typewriter
                    options={{
                      strings: text,
                      autoStart: true,
                      loop: false,
                      delay: 50
                    }}
                  />}
                </p>
                <div className='bottom-arrow-div'>
                  <LazyLoad alt="arrow" src="https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen6/Down_Arrows.png" />
                </div>
              </div>
            </div>
          </div>
          {/* <LazyLoad alt="number" src="https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen7/02_Smaller.png" className='number' /> */}
        </div>
      </>
      }
      {window.matchMedia("(max-width: 770px)").matches &&
        <div className='jack-duels-mobile'>
          <div className='inner-container'>
            <div className='mobile-caption' ref={jackDulesRef}>
              <div className='caption-inner'>
                <LazyLoad className='number_img jack-image' src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen7/02_num.png" />
                <h6 className='section-heading'>challenge other<br /> players</h6>
                <p  className='mobile-caption-message'>
                  {text}
                </p>
              </div>
            </div>
            <div className='frame-container'>
              <div className="video-upper-container">
                <div className='d-flex justify-content-end w-100 h-100'>
                  <div className='video-container'>
                    <LazyLoad src="https://d14utwz37q7qa4.cloudfront.net/assets/images/screen8/Frame.png" className='frame' />
                    <LazyLoadComponent>
                      <video loop autoPlay muted preload="none">
                        <source src={'https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen7/video_jack_duels.mp4'} type="video/mp4" />
                      </video>
                    </LazyLoadComponent>
                  </div>
                </div>

              </div>
            </div>
            <div className='bottom-arrow-div'>
                  <LazyLoad alt="arrow" src="https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen6/Down_Arrows.png" />
            </div>
          </div>
        </div>
      }
    </section>
  );
}

export default JackDuels;
