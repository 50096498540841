import './conquerMetaverse.scss';
import LazyLoad from '../common/lazyLoad';
import { LazyLoadComponent } from 'react-lazy-load-image-component';



function ConquerMetaverse() {
   

  return (
    <LazyLoadComponent>
         <section className="dark-blue-bg conquer-metaverse-section">
        <div className='conquer-metaverse-caption text-center'>
            <h6 className='section-heading'>
                Conquer the metaverse
            </h6>
            <p>
                JACK is built on blockchain and uses Augmented Reality to deliver an unprecedented immersive gaming experience.
            </p>
        </div>
        <div className='container gif-container' >
            <LazyLoad className="gif" src = {'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen4/conquere-metaverse-gif.gif'}/>
            <LazyLoad className="border-right" src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen4/border-right.svg'}/>
            <LazyLoad className="border-left" src={'https://d14utwz37q7qa4.cloudfront.net/assets/images/screen4/border-left.svg'}/>
        </div>
        </section>
    </LazyLoadComponent>
  );
}

export default ConquerMetaverse;
